import getDate from './modules/getDate.js'
import nav from './modules/nav.js'
// import modals from './modules/mccModals.js'
// import slider from './modules/mcc.carousel.js'
// import shoppingMadeSimple from './modules/shoppingMadeSimple.js'
// import sweepstakesModal from './modules/mccSweepstakes'
// import movingTabSwitch from './modules/movingTabSwitch'
// import tvCarousel from './modules/tvProductInfoCarousel.js'
// import internetCarousel from './modules/internetProductInfoCarousel.js'
// import infoTabSwitch from './modules/infoTabSwitch.js'
// import cardCarousel from './modules/cardCarousel.js'

getDate()
nav()
// modals()
// slider()
// shoppingMadeSimple()
// sweepstakesModal()
// movingTabSwitch()
// tvCarousel()
// internetCarousel()
// infoTabSwitch()
// cardCarousel()
