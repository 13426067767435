import * as doubleTapToGo from 'jquery-doubletaptogo' // eslint-disable-line no-unused-vars

export default () => {

  let body = $('body')
  let w = $(window).width()
  let max_w = 992 // scss var => $screen-large
  let has_touch = $('html').hasClass('touchevents')
  let navToggle = $('#nav-mobile-toggle')
  let nav = $('#mcc-navigation')
  let listItem = $('#mcc-navigation .list-item')
  let icon = navToggle.find('.fa')

  // nav functions

  function toggleSubMenuArrow(el) {
    // check for any open sub menus and replace with down arrow
    $('.fa-angle-up').each(function() {
      $(this).removeClass('fa-angle-up').addClass('fa-angle-down')
    })

    // find the element event target and toggle arrow up or down
    let angle = $(el.target).find('[class*="angle"]')
    angle.toggleClass('fa-angle-down fa-angle-up')

  }

  function topOfPageCheck() {
    if (nav.data('style') === 'lite') {
      nav.addClass('lite')
    } else {
      var scroll = $(window).scrollTop()
      // console.log(scroll)
      if (scroll >= 50) {
        nav.addClass('lite')
      } else {
        nav.removeClass('lite')
      }
    }
  }

  // do not fire nav stuff until doc ready
  $(document).ready(function() {

    // check if at top of page on page load, add .lite class if not
    topOfPageCheck()

    // toggle .lite class if window scroll > 50
    $(window).scroll(function() {
      topOfPageCheck()
    })

    // detect window size change, if window size changes from
    // mobile layout to desktop (992px) remove 'open-mobile' class
    // if it exists...
    // this is really for my own sanity when switching between layouts
    // and strangers who like breaking things
    $(window).resize( function() {

      let win = $(window).width()

      if(win >= max_w) {
        nav.removeClass('open-mobile')
        body.removeClass('disable-scroll')
        $('.overlay').remove()

        if(icon.hasClass('fa-times')) {
          icon.toggleClass('fa-bars fa-times')
        }
      }
    })

    // if touch device ->
    // single touch to open sub-nav-menu
    // double tap to open links
    if(has_touch) {
      nav.doubleTapToGo()
    }

    // if mobile layout,
    if(w < max_w) {
      // open mobile menu on click/touch
      navToggle.on('click', function() {
        // toggle nav icon
        if(icon.hasClass('fa-times')) {
          $('.overlay').remove()
          body.removeClass('disable-scroll')
        } else {
          body.append('<div class="overlay"></div>')
          body.addClass('disable-scroll')
        }

        icon.toggleClass('fa-bars fa-times')

        // toggle mobile menu open
        nav.toggleClass('open-mobile')
      })

      // open sub menu for mobile
      $('.list-item').on('click', 'a', function(el) {
        toggleSubMenuArrow(el)
      })
    }

    // toggle carrot arrow up/down on nav list item
    listItem.hover(function(el) {
      toggleSubMenuArrow(el)
    })
  }) //- end document.ready

} //- end of export

//- nav bar behavior for desktop view controlled by CSS styles
